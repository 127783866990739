/*
 * @Author: wangqs 
 * @description 机构分期
 * @Date: 2021-07-11 16:24:29 
 * @Last Modified by: wangqs
 * @Last Modified time: 2022-02-13 13:39:39
 */
<template>
  <div class="page-box box">
    <div class="img-box box">
      <van-image :src="VUE_APP_STATIC_URL + 'org-period-bg.png'"></van-image>
    </div>
    <div class="first-box form-box box">
      <div class="title">
        填写申请表
      </div>
      <van-field
        v-model="form.orgName"
        required
        readonly
        input-align="right"
        label="机构"
        placeholder="请选择机构"
        @click="showPickerA = true"
      />

      <van-field
        v-model="form.contractName"
        required
        input-align="right"
        label="联系人姓名"
        placeholder="请输入联系人姓名"
      />

      <van-field
        v-model="form.contractMobile"
        required
        input-align="right"
        type="number"
        label="联系电话"
        placeholder="请输入联系人电话"
      />

      <van-field
        v-model="form.contractIdCardNo"
        required
        input-align="right"
        type="number"
        label="联系人身份证"
        placeholder="请输入联系人身份证号"
      />

      <van-field
        v-model="showApplyAmount"
        required
        readonly
        input-align="right"
        label="申请金额"
        placeholder="请选择申请金额"
        @click="showPickerB = true"
      />
      <van-field
        v-model="showPeriod"
        required
        readonly
        input-align="right"
        label="分期期数"
        placeholder="请选择分期期数"
        @click="showPickerD = true"
      />

      <van-field
        v-model="showType"
        required
        input-align="right"
        readonly
        label="款项用途"
        placeholder="请选择款项用途"
        @click="showPickerC = true"
      />

      <!-- 机构选择 -->
      <van-popup
        v-model="showPickerA"
        position="bottom"
      >
        <van-search
          v-model="orgName"
          show-action
          placeholder="搜索机构名称"
          @search="searchFun"
        >
          <template #action>
            <div
              class="hr-btn"
              @click="searchFun"
            >
              搜索
            </div>
          </template>
        </van-search>
        <van-picker
          show-toolbar
          :columns="orgList"
          @confirm="(e) => onConfirm(e, 'showPickerA')"
          @cancel="showPickerA = false"
        />
      </van-popup>
      <van-popup
        v-model="showPickerB"
        position="bottom"
      >
        <van-picker
          show-toolbar
          :columns="priceArr"
          @confirm="(e) => onConfirm(e, 'showPickerB')"
          @cancel="showPickerB = false"
        />
      </van-popup>

      <van-popup
        v-model="showPickerD"
        position="bottom"
      >
        <van-picker
          show-toolbar
          :columns="periodArr"
          @confirm="(e) => onConfirm(e, 'showPickerD')"
          @cancel="showPickerD = false"
        />
      </van-popup>

      <!-- 款项用途 -->
      <van-popup
        v-model="showPickerC"
        position="bottom"
      >
        <van-picker
          show-toolbar
          :columns="typeArr"
          @confirm="(e) => onConfirm(e, 'showPickerC')"
          @cancel="showPickerC = false"
        />
      </van-popup>
    </div>

    <!-- 提交 -->
    <van-button
      class="submit-btn"
      color="#51B464"
      round
      type="info"
      @click="submit"
    >
      提交
    </van-button>
  </div>
</template>

<script>
import { areaList } from '@vant/area-data';
// import SelectAddr from '@components/common/addrSelect.vue'
export default {
  name: 'Settled',
  components: {
    // SelectAddr
  },
  data () {
    return {
      licenceUrl: [],
      formObj: {
        orgNo: {
          name: '机构'
        },
        applyAmount: {
          name: '申请金额'
        },
        period: {
          name: '分期期数'
        },
        type: {
          name: '款项用途'
        },
        contractName: {
          name: '联系人姓名'
        },
        contractMobile: {
          name: '联系人电话'
        },
        contractIdCardNo: {
          name: '联系人身份证号'
        }
      },
      typeArr: [
        {text: '增开新店', key: '1'},
        {text: '老店升级', key: '2'},
        {text: '采购教具', key: '3'},
        {text: '活动宣传', key: '4'},
        {text: '日常运营', key: '5'},
        {text: '其他', key: '6'}
      ],
      priceArr: [
        {text: '10万', key: '10'},
        {text: '20万', key: '20'},
        {text: '30万', key: '30'}
      ],
      periodArr: [
        {text: '3期', key: '3'},
        {text: '6期', key: '6'},
        {text: '9期', key: '9'},
        {text: '12期', key: '12'}
      ],
      form: {
        'applyAmount': '',
        'contractMobile': '',
        'contractIdCardNo': '',
        'contractName': '',
        'orgNo': '',
        'period': 0,
        'type': 0,
        'userId': 0
      },
      showApplyAmount: '',
      showPeriod: '',
      showType: '',
      orgName: '',
      schoolItem: {
        addrDetail: '',
        areaNo: '',
        cityNo: '',
        addrStr: '',
        provinceNo: '',
        schoolName: ''
      },
      showArea: false,
      showPickerA: false,
      showPickerB: false,
      showPickerC: false,
      showPickerD: false,
      addrStr: '',
      radio: '',
      areaList,
      orgList: [],
      oldList: []
    }
  },
  computed: {
  },
  created () {
    this.getOrgList()
  },
  methods: {
    // 入住前信息
    async getOrgList () {
      let res = await this.$http.orgApi.orgList({})
      if (res.code == 200) {
        let list = res.msg || []
        let arr = []
        for (let i of list) {
          let obj = {
            text: i.orgName || '',
            key: i.orgNo || ''
          }
          arr.push(obj)
        }
        this.oldList = arr
        this.orgList = arr
      }
    },
    // 搜索
    searchFun () {
      let list = this.$deepClone(this.oldList)
      let arr = []
      for (let i of list) {
        if (i.text.indexOf(this.orgName) > -1) {
          arr.push(i)
        }
      }
      this.orgList = arr
    },
    // 表单校验
    filterFrom () {
      for (let i in this.formObj || {}) {
        if (!this.form[i]) {
          this.$toast('请完善' + this.formObj[i].name)
          return false
        }
      }
      return true
    },
    // 表单提交
    async submit () {
      if (this.filterFrom()) {
        let res = await this.$http.orgApi.orgOrderCreate(this.form)
        if (res.code === 200) {
          this.$dialog.alert({
            title: '申请成功',
            message: '客服人员会尽快与您取得联系，请保持手机畅通',
            theme: 'round-button',
          }).then(() => {
            // on close
            this.$router.back()
          });
        }
      }
    },
    afterRead (file, key) {
      console.log(file)
      let vm = this;
      var fd = new FormData()
      // fd.append('Content-Type', 'multipart/form-data')
      fd.append('multipartFile', file.file)
      fd.append('dir', 'org')
      vm.$toast.loading({
        message: '加载中',
        forbidClick: true,
        duration: 0
      });
      var xhr = new XMLHttpRequest()
      xhr.open('POST', '/gateway/img/upload', true)
      xhr.onload = function () {
        if (Math.floor(xhr.status / 100) === 2) {
          var res = JSON.parse(xhr.response)
          if (res.code === 200) {
            console.log(res, '上传成功')
            // file.url = res.msg.url || ''
            vm.form[key] = res.msg.url || ''
          } else {
            vm.$toast.clear()
            vm.$toast('上传失败，请检查后重新上传')
          }
        } else {
          // clearInterval(vm.timerClock)
          vm.$toast.clear()
          vm.$toast('上传失败')
        }
        vm.$toast.clear()
      }
      xhr.onerror = function () {
        vm.$toast.clear()
        vm.$toast.fail('文件 ' + file.name + ' 上传失败')
      }
      xhr.setRequestHeader('token', JSON.parse(window.localStorage.getItem('userData') || '{}').token)
      xhr.send(fd)
    },
    onOversize (file) {
      console.log(file);
      this.$toast('文件大小不能超过 2M');
    },
    deleteItem (e, key) {
      console.log(e, key)
    },
    onConfirmCity (values) {
      console.log(values, 'sss')
      this.addrStr = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join('/');
      this.form.provinceNo = values[0].code
      this.form.cityNo = values[1].code
      this.form.areaNo = values[2].code
      this.form.provinceName = values[0].name
      this.form.cityName = values[1].name
      this.form.areaName = values[2].name
      this.showArea = false;
    },
    showAreaFun (item, index) {
      this.$refs.selectAddr[index].show(item, index)
    },
    cityChange (item, index) {
      console.log(item, index)
      this.form.schoolList[index].provinceNo = item.provinceNo
      this.form.schoolList[index].cityNo = item.cityNo
      this.form.schoolList[index].areaNo = item.areaNo
      if (item.provinceNo) {
        this.form.schoolList[index].addrStr = `${item.provinceName}/${item.cityName}/${item.areaName}` 
      }
      console.log(item, index, this.form.schoolList[index])
    },
    // 添加校区
    addSchool () {
      let item = this.$deepClone(this.schoolItem)
      this.form.schoolList.push(item)
    },
    // 款项用途
    onConfirm (e, key) {
      if (key  === 'showPickerA') {
        this.form.orgName = e.text
        this.form.orgNo = e.key;
      } else if (key === 'showPickerB') {
        this.showApplyAmount = e.text
        this.form.applyAmount = e.key;
      } else if (key === 'showPickerD') {
        this.showPeriod = e.text
        this.form.period = e.key;
      } else if (key === 'showPickerC') {
        this.showType = e.text
        this.form.type = e.key;
      }
      // this[ket + 'text'] = e.text
      this[key] = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.page-box {
  padding-bottom: 60px;
  padding-bottom: calc(60px + constant(safe-area-inset-bottom)); ///兼容 IOS<11.2/
  padding-bottom: calc(60px + env(safe-area-inset-bottom)); ///兼容 IOS>11.2/
  .img-box {
    height: 215px;
    .van-image {
      width: 100%;
      height: 100%;
    }
  }
  .form-box {
    background: #fff;
    padding: 0 15px;
    .title {
      font-size: 15px;
      line-height: 48px;
      font-weight: bold;
      text-align: center;
    }
  }
  .first-box {
    border-radius: 6px 6px 0 0;
    margin-top: -6px;
  }
  .upload-out-box {
    padding: 10px 8px 0;
    .title {
      margin-bottom: 10px;
    }
  }
  .submit-btn {
    width: 346px;
    position: fixed;
    bottom: 20px;
    bottom: calc(10px + constant(safe-area-inset-bottom)); ///兼容 IOS<11.2/
    bottom: calc(10px + env(safe-area-inset-bottom)); ///兼容 IOS>11.2/
    left: 50%;
    margin-left: -173px;
  }
  .school-box {
    .school-item {
      // margin-bottom: 10px;
      padding-left: 30px;
      .delete-icon {
        font-size: 20px;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
}
</style>
